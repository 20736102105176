












import { Inject } from 'inversify-props';
import moment from 'moment';
import { Component, Mixins } from 'vue-property-decorator';

import DownloadReportForm, { DownloadReportControlMixin } from '@/modules/common/components/download-report-form.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MonthPicker from '@/modules/common/components/ui-kit/month-picker.vue';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import { RatesClusterExcelForm } from '../interfaces/rates-cluster-excel-form.interface';
import ClusterRatesService, { ClusterRatesServiceS } from '../cluster-rates.service';
import ClusterService, { ClusterServiceS } from '../cluster.service';

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class RatesDownloadExcelPopup extends Mixins(DownloadReportControlMixin) {
    @Inject(ClusterRatesServiceS)
    private clusterRatesService!: ClusterRatesService;

    @Inject(ClusterServiceS)
    private clusterService!: ClusterService;

    @Inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    public form: RatesClusterExcelForm = {} as RatesClusterExcelForm;
    private isLoading = false;

    public get attrs() {
        const { filters, customColumns } = this;
        const { properties, buttons } = this;
        const { isLoading } = this;

        return {
            filters,
            customColumns,
            properties,
            buttons,
            isLoading,
            dataType: 'Rates',
        };
    }

    private get isDownloadDisabled() {
        const { provider, monthrange } = this.form;

        if (!monthrange || !provider) {
            return true;
        }

        return false;
    }

    private get buttons() {
        const buttons = [
            {
                label: 'Download now',
                onClick: () => this.downloadExcel(),
                isDisabled: this.isDownloadDisabled,
            },
            {
                label: 'Send to email',
                onClick: () => this.downloadExcel(true),
                isDisabled: this.isDownloadDisabled,
            },
        ];

        return buttons;
    }

    private get properties() {
        const { month, year } = this.documentFiltersService;
        return [
            {
                label: 'Date range',
                key: 'monthrange',
                component: MonthPicker,
                default: new Date(year, month),
                props: {
                    locale: this.$i18n.locale,
                },
            },
        ];
    }

    private get customColumns() {
        return [
            {
                key: 'change',
                label: 'Change',
                default: true,
            },
            {
                key: 'score',
                label: 'Score',
                default: true,
            },
            {
                key: 'diff_delta',
                label: 'Diff #',
                default: true,
            },
            {
                key: 'diff_precent',
                label: 'Diff %',
                default: true,
            },
        ];
    }

    private get filters() {
        const providerItems = this.clusterRatesService.providers.map(p => ({
            name: this.providersService.getProviderLabel(p),
            value: p,
        }));

        const filters = [
            {
                label: 'Source',
                key: 'provider',
                options: providerItems,
                default: this.clusterService.currentProvider,
            },
        ];

        return filters;
    }

    private async downloadExcel(toEmail = false) {
        try {
            this.isLoading = true;
            const { form } = this;

            const monthrange = [
                moment(form.monthrange).format('YYYY-MM-DD'),
                moment(new Date(form.monthrange.getFullYear(), form.monthrange.getMonth() + 1, 0)).format('YYYY-MM-DD'),
            ];

            const columns = Object.entries(form.columns).map(([key, value]) => ({
                name: key,
                value,
            }));

            await this.clusterRatesService.downloadExcel(monthrange, form.provider, columns, toEmail);

            if (toEmail) {
                this.triggerFormMessage('Report sent');
            } else {
                this.closeForm();
            }
        } catch (_) {
            this.triggerFormError('There is no data. Try change the filters.');
        } finally {
            this.isLoading = false;
        }
    }
}
